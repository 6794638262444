import { RouteObject } from "react-router-dom";
import App from "~/components/App";
import ApplicationLayout from "~/components/ApplicationLayout";
import EmailAddressVerificationBlock from "~/components/EmailAddressVerificationBlock";
import RootLayout from "~/components/RootLayout";
import SiteLayout from "~/components/SiteLayout";
import withAuthorization from "~/components/withAuthorization";
import withRequiredSearchParam from "~/components/withRequiredSearchParam";

const routes: Array<RouteObject> = [
  {
    Component: App,
    children: [
      {
        Component: RootLayout,
        children: [
          {
            Component: SiteLayout,
            children: [
              { lazy: () => import("~/components/Home"), path: "" },
              { lazy: () => import("~/components/AffiliatePage"), path: "affiliate" },
              {
                children: [
                  { lazy: () => import("~/components/ForOpenSource"), path: "open-source" },
                ],
                path: "for",
              },

              {
                children: [
                  { lazy: () => import("~/components/DisposableEmailChecker"), path: "disposable-email-address-checker" },
                  { lazy: () => import("~/components/SpeedTest"), path: "website-speed-test" },
                ],
                path: "tools",
              },

              {
                caseSensitive: true,
                children: [
                  { lazy: () => import("~/components/BlogPage"), path: "" },
                  { caseSensitive: true, lazy: () => import("~/components/BlogPage"), path: "page/:blogPage" },
                  { caseSensitive: true, lazy: () => import("~/components/BlogPost"), path: ":blogPostSlug" },
                ],
                path: "blog",
              },
              {
                caseSensitive: true,
                children: [
                  { caseSensitive: true, lazy: () => import("~/components/CronJobMonitoringServicePage"), path: "cron-job-monitoring" },
                  { caseSensitive: true, lazy: () => import("~/components/PingMonitoringServicePage"), path: "ping-monitoring" },
                  { caseSensitive: true, lazy: () => import("~/components/StatusPageServicePage"), path: "status-pages" },
                  { caseSensitive: true, lazy: () => import("~/components/UptimeMonitoringService"), path: "uptime-monitoring" },
                ],
                path: "services",
              },
              {
                caseSensitive: true,
                children: [
                  { lazy: () => import("~/components/DocsArticle"), path: "" },
                  { caseSensitive: true, lazy: () => import("~/components/DocsArticle"), path: ":docsCategorySlug/:docsArticleSlug" },
                ],
                path: "docs",
              },
              { caseSensitive: true, lazy: () => import("~/components/AlternativeToPingdom"), path: "alternative-to-pingdom" },
              { caseSensitive: true, lazy: () => import("~/components/AlternativeToUptimeRobot"), path: "alternative-to-uptimerobot" },
              { caseSensitive: true, lazy: () => import("~/components/Pricing"), path: "pricing" },
              { caseSensitive: true, lazy: () => import("~/components/PrivacyPolicy"), path: "privacy-policy" },
              { caseSensitive: true, lazy: () => import("~/components/TermsOfService"), path: "terms-of-service" },
              { lazy: () => import("~/components/NotFound"), path: "*" },
            ],
            handle: {
              headerVariant: "site",
            },
          },

          {
            Component: ApplicationLayout,
            children: [
              {
                Component: withAuthorization({ isAuthenticated: true }),
                children: [
                  { caseSensitive: true, lazy: () => import("~/components/EmailAddressVerification"), path: "email-address-verification" },
                ],
              },

              // e-mail verification block
              {
                Component: EmailAddressVerificationBlock,
                caseSensitive: true,
                children: [

                  // alerts
                  {
                    caseSensitive: true,
                    children: [
                      {
                        Component: withAuthorization({ isAuthenticated: true }),
                        children: [
                          { lazy: () => import("~/components/Alerts"), path: "" },
                        ],
                      },
                      {
                        Component: withAuthorization({ isAuthenticated: true, redirectTo: "/alerts", roles: ["ADMIN", "OWNER"] }),
                        children: [
                          { caseSensitive: true, lazy: () => import("~/components/AlertForm"), path: "add" },
                          {
                            Component: withRequiredSearchParam({
                              check: (v) => !Number.isNaN(Number(v)),
                              redirectTo: "/alerts",
                              searchParamName: "id",
                            }),
                            children: [
                              { caseSensitive: true, lazy: () => import("~/components/AlertForm"), path: "edit" },
                            ],
                          },
                        ],
                      },
                    ],
                    path: "alerts",
                  },

                  // billing
                  {
                    Component: withAuthorization({ isAuthenticated: true, roles: ["OWNER"] }),
                    children: [
                      { caseSensitive: true, lazy: () => import("~/components/Billing"), path: "billing" },
                    ],
                  },

                  // monitors
                  {
                    caseSensitive: true,
                    children: [
                      {
                        Component: withAuthorization({ isAuthenticated: true }),
                        children: [
                          { lazy: () => import("~/components/Monitors"), path: "" },
                          { lazy: () => import("~/components/MonitorForm"), path: "add" },
                          {
                            Component: withRequiredSearchParam({
                              check: (v) => !Number.isNaN(Number(v)),
                              redirectTo: "/monitors",
                              searchParamName: "id",
                            }),
                            children: [
                              { caseSensitive: true, lazy: () => import("~/components/MonitorForm"), path: "edit" },
                            ],
                          },
                          {
                            Component: withRequiredSearchParam({
                              check: (v) => !Number.isNaN(Number(v)),
                              redirectTo: "/monitors",
                              searchParamName: "id",
                            }),
                            children: [
                              { caseSensitive: true, lazy: () => import("~/components/MonitorReport"), path: "report" },
                            ],
                          },
                        ],
                      },
                    ],
                    path: "monitors",
                  },

                  // status pages
                  {
                    caseSensitive: true,
                    children: [
                      {
                        Component: withAuthorization({ isAuthenticated: true }),
                        children: [
                          { lazy: () => import("~/components/StatusPages"), path: "" },
                          {
                            children: [
                              { lazy: () => import("~/components/StatusPageIncidents"), path: "" },
                              {
                                Component: withRequiredSearchParam({
                                  check: (v) => !Number.isNaN(Number(v)),
                                  redirectTo: "/status-pages",
                                  searchParamName: "statusPageId",
                                }),
                                children: [
                                  { caseSensitive: true, lazy: () => import("~/components/StatusPageIncidentForm"), path: "edit" },
                                ],
                              },
                            ],
                            path: "incidents",
                          },
                        ],
                      },
                      {
                        Component: withAuthorization({ isAuthenticated: true, redirectTo: "/status-pages", roles: ["ADMIN", "OWNER"] }),
                        children: [
                          { caseSensitive: true, lazy: () => import("~/components/StatusPageForm"), path: "add" },
                          {
                            Component: withRequiredSearchParam({
                              check: (v) => !Number.isNaN(Number(v)),
                              redirectTo: "/status-pages",
                              searchParamName: "id",
                            }),
                            children: [
                              { caseSensitive: true, lazy: () => import("~/components/StatusPageForm"), path: "edit" },
                            ],
                          },
                          {
                            caseSensitive: true,
                            children: [
                              { caseSensitive: true, lazy: () => import("~/components/StatusPageIncidentForm"), path: "add" },
                            ],
                            path: "incidents",
                          },
                        ],
                      },
                    ],
                    path: "status-pages",
                  },

                  // team
                  {
                    caseSensitive: true,
                    children: [
                      {
                        Component: withAuthorization({ isAuthenticated: true }),
                        children: [
                          { lazy: () => import("~/components/Team"), path: "" },
                        ],
                      },
                      {
                        Component: withAuthorization({ isAuthenticated: true, redirectTo: "/team", roles: ["OWNER"] }),
                        children: [
                          {
                            Component: withRequiredSearchParam({
                              check: (v) => !Number.isNaN(Number(v)),
                              redirectTo: "/team",
                              searchParamName: "id",
                            }),
                            children: [
                              { caseSensitive: true, lazy: () => import("~/components/TeamMemberForm"), path: "edit" },
                            ],
                          },
                          { caseSensitive: true, lazy: () => import("~/components/TeamMemberForm"), path: "invite" },
                        ],
                      },
                    ],
                    path: "team",
                  },
                ],
              },

              // settings
              {
                caseSensitive: true,
                children: [
                  {
                    Component: withAuthorization({ isAuthenticated: true }),
                    children: [
                      { caseSensitive: true, lazy: () => import("~/components/Settings/Password"), path: "password" },
                      { caseSensitive: true, lazy: () => import("~/components/Settings/Preferences"), path: "preferences" },
                      { caseSensitive: true, lazy: () => import("~/components/Settings/Profile"), path: "profile" },
                    ],
                  },
                  {
                    Component: withAuthorization({ isAuthenticated: true, redirectTo: "/settings", roles: ["OWNER"] }),
                    children: [
                      { caseSensitive: true, lazy: () => import("~/components/Settings/AccountDeletion"), path: "account-deletion" },
                      {
                        caseSensitive: true,
                        lazy: () => import("~/components/Settings/OrganizationDetails"),
                        path: "organization-details",
                      },
                    ],
                  },
                ],
                lazy: () => import("~/components/Settings"),
                path: "settings",
              },

              // unauthenticated
              {
                Component: withAuthorization({ isAuthenticated: false }),
                children: [
                  { caseSensitive: true, lazy: () => import("~/components/Activation"), path: "activation" },
                  { caseSensitive: true, lazy: () => import("~/components/PasswordRecovery"), path: "password-recovery" },
                  { caseSensitive: true, lazy: () => import("~/components/PasswordReset"), path: "password-reset" },
                  { caseSensitive: true, lazy: () => import("~/components/SignIn"), path: "sign-in" },
                  { caseSensitive: true, lazy: () => import("~/components/SignUp"), path: "sign-up" },
                ],
              },
            ],
            handle: { headerVariant: "application" },
          },
        ],
      },
      {
        caseSensitive: true,
        lazy: () => import("~/components/StatusPage"),
        path: "s",
      },
    ],
  },
];

const statusPageRoutes: Array<RouteObject> = [
  {
    Component: App,
    children: [
      { lazy: () => import("~/components/StatusPage"), path: "" },
      { lazy: () => import("~/components/NotFound"), path: "*" },
    ],
  },
];

export function getRoutes(hostname: string): Array<RouteObject> {
  const baseUrl = new URL(import.meta.env.VITE_BASE_URL);

  return hostname === baseUrl.hostname ? routes : statusPageRoutes;
}
